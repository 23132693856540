
import { defineComponent } from 'vue'
import Cta from 'Atoms/cta'

export default defineComponent({
  name: 'CtaCustom',
  extends: Cta,
  methods: {
    handleClick: function (event) {
      console.log('click from CtaCustom', event) // eslint-disable-line no-console
    },
  },
})
